<template>
  <div class="workOrder">
	  <tab :tab="tab" ></tab>
	  <div class="workOrder-container">
		  <router-view></router-view>
	  </div>
  </div>
</template>

<script>
	import tab from '../components/tab/index.vue'
export default {
  name: '',
  components: {tab},
  data() {
    return {
		tab: [
			{
				name: '派单',
				path: '/transportManage/workOrder/sendOrders'
			},
			{
				name: '任务查询',
				path: '/transportManage/workOrder/taskQuery'
			},
			{
				name: '工勤管理',
				path: '/transportManage/workOrder/workers'
			}
		]
	}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
	.workOrder{
		height: 100%;
		background: linear-gradient(360deg, rgba(114, 171, 253, 0) 0%, #568efc 70%, #3e73fb 100%);
		background-size: 100% 195px;
		background-repeat: no-repeat;
	}
	.workOrder-container{
		height: calc(100% - 40px);
		margin-top: 4px;
		overflow-y: auto;
	}
</style>
