<template>
	<div class="tab-wrap">
		<ul class="tab-list">
			<li v-for="(item, index) in tab" :key="index" :class="{ active: item.path == $route.path }" @click="change(item)">{{ item.name }}</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'workOrder',
	components: {},
	props: {
		tab: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {
		change(item) {
			this.$router.replace(item.path);
		}
	}
};
</script>

<style lang="scss" scoped>
.tab-wrap {
	height: 36px;
	width: 100%;
	.tab-list {
		height: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		font-size: 14px;
		li {
			height: 100%;
			color: #eee;
			position: relative;
			display: flex;
			align-items: center;
			&.active {
				color: #fff;
				font-weight: 700;
				&::after {
					content: '';
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					width: 18px;
					border-radius: 5px;
					bottom: 0;
					height: 3px;
					background: #fff;
				}
			}
		}
	}
}
</style>
